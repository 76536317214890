import { createStandaloneToast } from "@chakra-ui/react"
import { QueryClient } from "@tanstack/react-query"
import * as R from "ramda"
import * as Sentry from "@sentry/remix"

import { isClerkError } from "./isClerkError"
import { theme } from "./theme"
import { z } from "zod"

const toast = createStandaloneToast({
  theme,
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      onError(err) {
        Sentry.captureException(err)
      },
    },
    mutations: {
      retry: 3,
      onError(err) {
        Sentry.captureException(err)

        if (err && typeof err === "object" && "data" in err) {
          const creditError = z
            .object({
              data: z.object({
                response: z.object({
                  status: z.literal(429),
                }),
                request: z
                  .object({
                    url: z.string(),
                  })
                  .optional(),
              }),
            })
            .safeParse(err.data)

          if (creditError.success) {
            const url = creditError.data.data.request?.url
              ? new URL(creditError.data.data.request.url)
              : { hostname: undefined }
            const integrationName = url.hostname ? url.hostname : "integration"

            toast({
              status: "error",
              title: `It looks like you've run out of ${integrationName} API credits.`,
              description: `Please talk to ${integrationName} to resolve this issue.`,
            })

            return
          }
        }

        if (isClerkError(err)) {
          err.errors.forEach(({ message }) => {
            toast({
              status: "error",
              title: message,
            })
          })
          return
        }

        if (R.has("message", err) && typeof err.message === "string") {
          toast({
            status: "error",
            title: err.message,
          })
          return
        }

        toast({
          status: "error",
          title: "Uh oh! Something went wrong.",
        })
      },
    },
  },
})
